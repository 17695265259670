@if (showChatButton())
{
    <button
        mat-fab
        aria-label="Open chat"
        class="fixed bottom-5 right-5 z-90"
        (click)="chatDrawer.toggle()"
    >
        <mat-icon
            class="text-white icon-size-5"
            svgIcon="mat_solid:forum"
        >
        </mat-icon>
    </button>
}

<fuse-drawer
    class="z-999 w-screen min-w-screen sm:w-100 sm:min-w-100"
    fixed
    [mode]="'over'"
    [name]="'chatDrawer'"
    [position]="'right'"
    #chatDrawer
>
    <div class="relative bg-card flex w-full flex-col overflow-auto">
        <button
            mat-icon-button
            class="absolute top-2 right-2 bg-white z-10"
            (click)="chatDrawer.close()"
        >
            <mat-icon
                class="text-current"
                svgIcon="mat_solid:close"
            >
            </mat-icon>
        </button>
        <au-chat-timeline
            class="flex flex-auto flex-col h-full max-h-full min-w-72 overflow-hidden bg-white dark:bg-transparent"
            [chatMessages]="messages()"
            [currentAccount]="currentAccount()"
            [showSpinner]="showSpinner()"
            [typeLastMessage]="typeLastMessage()"
            (scroll)="handlerScroll($event)"
            (sendMessage)="
                actionService.action({
                    id          : 'digitai::generalChatConversation.list.sendMessage',
                    isViewAction: false,
                    meta: {
                        messageInput: $event,
                    }
                })
            "
        >
        </au-chat-timeline>
    </div>
</fuse-drawer>
