import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { messageColumnsConfig, MessageService } from '@apps/chat/message';
import { Account, Action, ChatMessage, ChatTimelineComponent, GridData, GridFiltersStorageService, GridStateService, IamService, now, queryStatementHandler, uuid, ViewBaseComponent } from '@aurora';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { DigitaiCreateFleetMessage, DigitChatGroup } from '../digitai.types';
import { GeneralChatService } from './general-chat.service';
import { filter, lastValueFrom } from 'rxjs';
import { ChatMessageStatus } from '@apps/chat';
import { FleetService } from '../fleet';

export const generalChatConversationPaginationScope: string = 'digitai::generalChatConversation';

@Component({
    selector: 'general-chat',
    templateUrl: './general-chat.component.html',
    styles: `
        general-chat {
            position: static;
            display: block;
            flex: none;
            width: auto;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ChatTimelineComponent,
        DatePipe,
        FuseDrawerComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgClass,
        TextFieldModule,
    ],
})
export class GeneralChatComponent extends ViewBaseComponent
{
    // ---- customizations ----
    actionScope: string = 'digitai::generalChatConversation.list';
    messages: WritableSignal<ChatMessage[]> = signal(null);
    currentAccount: WritableSignal<Account> = signal(null);
    showSpinner: WritableSignal<boolean> = signal(false);
    typeLastMessage: WritableSignal<boolean> = signal(false);
    showChatButton: WritableSignal<boolean>;
    currentLimit: number = 20;

    constructor(
        private readonly messageService: MessageService,
        private readonly generalChatService: GeneralChatService,
        private readonly gridFiltersStorageService: GridFiltersStorageService,
        private readonly gridStateService: GridStateService,
        private readonly iamService: IamService,
        private readonly fleetService: FleetService,
    )
    {
        super();
    }

    init(): void
    {
        this.showChatButton = this.generalChatService.showChatButton;

        this.currentAccount.set(this.iamService.me);

        // create subscription to get messages
        this.messageService
            .getScopePagination(generalChatConversationPaginationScope)
            .pipe(
                filter(pagination => pagination !== null && pagination.rows !== null)
            )
            .subscribe((pagination: GridData<ChatMessage>) => this.messages.set(pagination.rows.reverse()));

        // call first action to get messages, this block should be in the resolver,
        // but layout component is not a view component with resolver
        const gridId = 'digitai::generalChatConversation.list.mainGridList';
        this.gridStateService.setPaginationActionId(gridId, 'digitai::generalChatConversation.list.pagination');
        this.gridStateService.setExportActionId(gridId, 'digitai::generalChatConversation.list.export');
        this.messageService
            .pagination({
                query: queryStatementHandler({ columnsConfig: messageColumnsConfig })
                    .setColumFilters(this.gridFiltersStorageService.getColumnFilterState(gridId))
                    .setSort(this.gridStateService.getSort(gridId, { active: 'createdAt', direction: 'desc' }))
                    .setPage(this.gridStateService.getPage(gridId, { pageIndex: 0, pageSize: 20 }))
                    .setSearch(this.gridStateService.getSearchState(gridId))
                    .getQueryStatement(),
                constraint: {
                    where: {
                        objectId: this.currentAccount().id,
                    },
                    include: [
                        {
                            association: 'account',
                        },
                        {
                            association: 'group',
                            where: {
                                code: DigitChatGroup.GENERAL_CHAT,
                            },
                        },
                    ],
                },
                scope: generalChatConversationPaginationScope,
            })
            .subscribe();
    }

    handlerScroll($event)
    {
        const element = $event.target as HTMLElement;
        if (element.scrollHeight + element.scrollTop === element.clientHeight)
        {
            this.currentLimit += 20;
            this.actionService
                .action({
                    id          : 'digitai::generalChatConversation.list.getMessages',
                    isViewAction: true,
                });
        }
    }

    async handleAction(action: Action): Promise<void>
    {
        // add optional chaining (?.) to avoid first call where behaviour subject is undefined
        switch (action?.id)
        {
            /* #region actions to manage file manager chat */
            case 'digitai::generalChatConversation.list.getMessages':
                await lastValueFrom(
                    this.messageService
                        .pagination({
                            scope: generalChatConversationPaginationScope,
                            query: {
                                limit: this.currentLimit,
                                order: [['createdAt', 'desc']],
                            },
                            constraint: {
                                where: {
                                    objectId: this.currentAccount().id,
                                },
                                include: [
                                    {
                                        association: 'account',
                                    },
                                    {
                                        association: 'group',
                                        where: {
                                            code: DigitChatGroup.GENERAL_CHAT,
                                        },
                                    },
                                ],
                            },
                        })
                );

                this.showSpinner.set(false);
                break;

            case 'digitai::generalChatConversation.list.sendMessage':
                console.log('sendMessage', action.meta.messageInput);
                const messageId = uuid();

                this.messages
                    .update(messages => [...messages, {
                        id       : messageId,
                        groupId  : null,
                        objectId : this.currentAccount().id,
                        accountId: this.currentAccount().id,
                        account  : this.currentAccount(),
                        status   : ChatMessageStatus.SENT,
                        message  : action.meta.messageInput,
                        createdAt: now().format('YYYY-MM-DD HH:mm:ss'),
                    }]);

                this.showSpinner.set(true);

                await lastValueFrom(
                    this.fleetService
                        .sendMessageFleet<DigitaiCreateFleetMessage>({
                            object: {
                                id     : messageId,
                                message: action.meta.messageInput,
                            },
                        }),
                );

                this.typeLastMessage.set(true);

                this.actionService.action({
                    id          : 'digitai::generalChatConversation.list.getMessages',
                    isViewAction: true,
                });
                break;
                /* #endregion actions to manage file manager chat */
        }
    }
}
